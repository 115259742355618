import { combineReducers } from 'redux'
import { default as segments } from '../Store/Segments/SegmentsReducer.js'
import { default as products } from '../Store/Product/prodReducer.js'
import { default as productsByCat } from '../Store/Product/prodReducer.js'
import { default as fSliderPhotos } from '../Store/Product/prodReducer.js'
import { default as secSliderPhotos } from '../Store/Product/prodReducer.js'
import { default as singleProd } from '../Store/Product/prodReducer.js'
import { default as sameProd } from '../Store/Product/prodReducer.js'
import { default as draftBeer } from '../Store/Product/prodReducer.js'
import { default as wishProd } from '../Store/Product/prodReducer.js'
import { default as cart } from '../Store/Product/prodReducer.js'
import { default as category } from '../Store/Category/CatReducer.js'
import { default as parentCategory } from '../Store/Category/CatReducer.js'
import { default as sliderPhoto } from '../Store/SliderPhotos/SliderPhotoReducer.js'
import { default as groupPhoto } from '../Store/SliderPhotos/SliderPhotoReducer.js'
import { default as address } from '../Store/Address/AddressReducer.js'
import { default as selfTaking } from '../Store/Address/AddressReducer.js'
import { default as addressMod } from '../Store/Address/AddressReducer.js'
import { default as addressMap } from '../Store/Address/AddressReducer.js'
import { default as DefAddress } from '../Store/Address/AddressReducer.js'
import { default as ChoosenAdr } from '../Store/Address/AddressReducer.js'
import { default as search } from '../Store/Search/SearchReducer.js'
import { default as searchPage } from '../Store/Search/SearchReducer.js'
import { default as payment } from '../Store/Checkout/CheckoutReducer.js'
import { default as orders } from '../Store/Orders/OrdersReducer.js'



const rootReducer = combineReducers({
  fSliderPhotos,
  secSliderPhotos,
  segments,
  products,
  category,
  sliderPhoto,
  productsByCat,
  singleProd,
  sameProd,
  draftBeer,
  wishProd,
  address,
  addressMod,
  addressMap,
  search,
  searchPage,
  payment,
  selfTaking,
  DefAddress,
  cart,
  orders,
  groupPhoto,
  ChoosenAdr,
  parentCategory,


})

export default rootReducer