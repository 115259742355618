import { SET_SEGMENTS } from './SegActions'

const initialState = {
  segments: [],
}

export default function segmentsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEGMENTS:
      return {
        ...state,
        segments: action.payload,
      }

    default:
      return state
  }
}