import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import {
  MAIN_PAGE, SEGMENT_PAGE, PRODUCTSBYCAT, SINGLE_PAGE,
  CARTPAGE, WISHPAGE, DRAFTBEER, LOGIN, SIGNUP, PROFILE, PASSCHANGE,
  USERADRESS, CHECKOUTPAGE, PASSWORD, SEARCHPAGE, ORDERS, TERMS, SPECS, PARENTCATEG, USERDELETION, USERRECOVERY
} from './routes.js'
// import Main from './Layout/Pages/Main.js'
// import Segments from './Layout/Pages/Segments.js'
// import Terms from './Layout/Pages/Terms.js'
// import Products from './Components/Products.js'
// import ProductSegments from './Components/ProductSegments.js'
// import ProdByCat from './Components/ProdByCat.js'
// import MainProf from './Components/MainProf.js'
// import PassChange from './Components/PassChange.js'
// import Orders from './Components/Orders.js'
// import UserAdress from './Components/UserAdress.js'
// import SingleProdPage from './Layout/Pages/SingleProdPage.js'
// import CartPage from './Layout/Pages/CartPage.js'
// import WishPage from './Layout/Pages/WishPage.js'
// import DraftBeer from './Layout/Pages/DraftBeer.js'
// import SearchPage from './Layout/Pages/SearchPage.js'
// import Password from './Layout/Pages/Password.js'
// import Login from './Layout/Pages/Login.js'
// import SignUp from './Layout/Pages/SignUp.js'
// import Checkout from './Layout/Pages/Checkout.js'
// import ParentCateg from './Layout/Pages/ParentCateg.js'
import Loader from './Loader.js'


const Main = lazy(() => import('./Layout/Pages/Main.js'));
const Segments = lazy(() => import('./Layout/Pages/Segments.js'));
const Terms = lazy(() => import('./Layout/Pages/Terms.js'));
const Products = lazy(() => import('./Components/Products.js'));
const ProductSegments = lazy(() => import('./Components/ProductSegments.js'));
const ProdByCat = lazy(() => import('./Components/ProdByCat.js'));
const MainProf = lazy(() => import('./Components/MainProf.js'));
const PassChange = lazy(() => import('./Components/PassChange.js'));
const Orders = lazy(() => import('./Components/Orders.js'));
const UserDeletion = lazy(() => import('./Components/UserDeletion.js'));
const UserAdress = lazy(() => import('./Components/UserAdress.js'));
const SingleProdPage = lazy(() => import('./Layout/Pages/SingleProdPage.js'));
const CartPage = lazy(() => import('./Layout/Pages/CartPage.js'));
const WishPage = lazy(() => import('./Layout/Pages/WishPage.js'));
const DraftBeer = lazy(() => import('./Layout/Pages/DraftBeer.js'));
const SearchPage = lazy(() => import('./Layout/Pages/SearchPage.js'));
const Password = lazy(() => import('./Layout/Pages/Password.js'));
const Login = lazy(() => import('./Layout/Pages/Login.js'));
const SignUp = lazy(() => import('./Layout/Pages/SignUp.js'));
const Checkout = lazy(() => import('./Layout/Pages/Checkout.js'));
const ParentCateg = lazy(() => import('./Layout/Pages/ParentCateg.js'));
const UserRecovery = lazy(() => import('./Layout/Pages/UserRecovery.js'));
const PrivateRoute = lazy(() => import('./PrivateRoute.js'));




function App() {



  return (
    <React.Fragment>
      <Router>
        <Routes>

          <Route exact path={ORDERS} element={
            <Suspense fallback={<Loader />}>
              <PrivateRoute redirectTo={LOGIN}>
                <Orders />
              </PrivateRoute>
            </Suspense>
          } />

          <Route exact path={CHECKOUTPAGE} element={
            <Suspense fallback={<Loader />}>
              <PrivateRoute redirectTo={LOGIN}>
                <Checkout />
              </PrivateRoute>
            </Suspense>
          } />

          <Route exact path={USERDELETION} element={
            <Suspense fallback={<Loader />}>
              <PrivateRoute redirectTo={LOGIN}>
                <UserDeletion />
              </PrivateRoute>
            </Suspense>
          } />

          <Route exact path={USERADRESS} element={
            <Suspense fallback={<Loader />}>
              <PrivateRoute redirectTo={LOGIN}>
                <UserAdress />
              </PrivateRoute>
            </Suspense>
          } />

          <Route exact path={PASSCHANGE} element={
            <Suspense fallback={<Loader />}>
              <PrivateRoute redirectTo={LOGIN}>
                <PassChange />
              </PrivateRoute>
            </Suspense>
          } />

          <Route exact path={PROFILE} element={
            <Suspense fallback={<Loader />}>
              <PrivateRoute redirectTo={LOGIN}>
                <MainProf />
              </PrivateRoute>
            </Suspense>
          } />

          <Route exact path={TERMS} element={
            <Suspense fallback={<Loader />}>
              <Terms />
            </Suspense>
          } />

          <Route exact path={SEARCHPAGE} element={
            <Suspense fallback={<Loader />}>
              <SearchPage />
            </Suspense>
          } />

          <Route exact path={PASSWORD} element={
            <Suspense fallback={<Loader />}>
              <Password />
            </Suspense>
          } />

          <Route exact path={USERRECOVERY} element={
            <Suspense fallback={<Loader />}>
              <UserRecovery />
            </Suspense>
          } />

          <Route exact path={LOGIN} element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          } />

          <Route exact path={SIGNUP} element={
            <Suspense fallback={<Loader />}>
              <SignUp />
            </Suspense>
          } />

          <Route exact path={DRAFTBEER} element={
            <Suspense fallback={<Loader />}>
              <DraftBeer />
            </Suspense>
          } />

          <Route exact path={WISHPAGE} element={
            <Suspense fallback={<Loader />}>
              <WishPage />
            </Suspense>
          } />

          <Route exact path={CARTPAGE} element={
            <Suspense fallback={<Loader />}>
              <CartPage />
            </Suspense>
          } />

          <Route exact path={SEGMENT_PAGE + PRODUCTSBYCAT + SINGLE_PAGE} element={
            <Suspense fallback={<Loader />}>
              <SingleProdPage />
            </Suspense>
          } />

          <Route exact path={SEGMENT_PAGE + PARENTCATEG + PRODUCTSBYCAT + SINGLE_PAGE} element={
            <Suspense fallback={<Loader />}>
              <SingleProdPage />
            </Suspense>
          } />

          <Route exact path={PRODUCTSBYCAT + SINGLE_PAGE} element={
            <Suspense fallback={<Loader />}>
              <SingleProdPage />
            </Suspense>
          } />

          <Route exact path={SEGMENT_PAGE + SINGLE_PAGE} element={
            <Suspense fallback={<Loader />}>
              <SingleProdPage />
            </Suspense>
          } />

          <Route exact path={SEGMENT_PAGE + PARENTCATEG + PRODUCTSBYCAT} element={
            <Suspense fallback={<Loader />}>
              <ProdByCat />
            </Suspense>
          } />

          <Route exact path={SEGMENT_PAGE + SPECS} element={
            <Suspense fallback={<Loader />}>
              <Products />
            </Suspense>
          } />

          <Route exact path={SEGMENT_PAGE + SPECS + SINGLE_PAGE} element={
            <Suspense fallback={<Loader />}>
              <SingleProdPage />
            </Suspense>
          } />
          <Route exact path={SINGLE_PAGE} element={
            <Suspense fallback={<Loader />}>
              <SingleProdPage />
            </Suspense>
          } />

          <Route exact path={SEGMENT_PAGE + PARENTCATEG} element={
            <Suspense fallback={<Loader />}>
              <ParentCateg />
            </Suspense>
          } />

          <Route exact path={SEGMENT_PAGE} element={
            <Suspense fallback={<Loader />}>
              <ProductSegments />
            </Suspense>
          } />

          <Route exact path={SEGMENT_PAGE} element={
            <Suspense fallback={<Loader />}>
              <Segments />
            </Suspense>
          } />

          <Route exact path={MAIN_PAGE} element={
            <Suspense fallback={<Loader />}>
              <Main />
            </Suspense>
          } />

        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
