import { SET_SLIDERPHOTO, SET_GROUPPHOTOS } from './SliderPhotoActions'

const initialState = {
  sliderPhoto: [],
  groupPhoto: [],
}

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SLIDERPHOTO:
      return {
        ...state,
        sliderPhoto: action.payload,
      }

    case SET_GROUPPHOTOS:
      return {
        ...state,
        groupPhoto: action.payload,
      }

    default:
      return state
  }
}