import {  SET_SEARCH, REMOVE_SEARCH, SET_SEARCH_PAGE } from './SearchAction.js'

const initialState = {
  search: [],
  searchPage: [],
}

export default function SearchReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      }
      break;

    case SET_SEARCH_PAGE:
      return {
        ...state,
        searchPage: action.payload,
      }
      break;

    case REMOVE_SEARCH:
      return {
        ...state,
        search: state.search.filter(
          (inSearch) => inSearch.UID !== action.payload,
        ),
      }
      break;

    default:
      return state
  }
}