export const MAIN_PAGE = '/'
export const SEGMENT_PAGE = '/segments/:id'
export const PRODUCTSBYCAT = '/products/:uid'
export const PARENTCATEG = '/par/:uiddd'
export const SINGLE_PAGE = '/product_page/:uidd'
export const SAMEPROD = '/:uiddd'
export const CARTPAGE = '/cart_page/:1'
export const WISHPAGE = '/wish_page/:1'
export const DRAFTBEER = '/draft_beer/page/:1'
export const LOGIN = '/User_login_Page'
export const SIGNUP = '/User_signUp_page'
export const PROFILE = '/User_profile_page'
export const PASSCHANGE = '/User_pass_change_page'
export const ORDERS = '/User_orders_page'
export const USERADRESS = '/User_adress_page'
export const USERDELETION = '/User_deletion_page'
export const CHECKOUTPAGE = '/Check_out_page'
export const PASSWORD = '/password'
export const USERRECOVERY = '/User_Recovery'
export const SEARCHPAGE = '/Search_results'
export const TERMS = '/Terms'
export const SPECS = '/Specs'
