export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_PRODUCTSBYCAT = 'SET_PRODUCTSBYCAT'
export const SET_SINGLEPROD = 'SET_SINGLEPROD'
export const SET_SAMEPROD = 'SET_SAMEPROD'
export const SET_DRAFTBEER = 'SET_DRAFTBEER'
export const SET_WISHPROD = 'SET_WISHPROD'
export const REMOVE_WISHPROD = 'REMOVE_WISHPROD'
export const SET_CARDPROD = 'SET_CARDPROD'
export const SET_SLIDERPHOTOS = 'SET_SLIDERPHOTOS'
export const SET_SECSLIDERPHOTOS = 'SET_SECSLIDERPHOTOS'
